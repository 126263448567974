<template>
  <div id="v-pills-workexperience" role="tabpanel" aria-labelledby="v-pills-workexperience-tab">
    <div class="col content">
      <h5 class="text-primary p-3">Work experience<span class="float-right"><span id="btnedit" role="button" @click.prevent="edit_exp = !edit_exp"><img :src="$S3_url+'/Assets/images/edit-icon-black.svg'" alt="edit experience" title="edit experience" width="25"></span></span></h5> 
      <hr>
      <form>
        <div id="a" class="p-4" v-if="!edit_exp">
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Total experience<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><p v-if="user_exp">{{user_exp}}</p><p v-else>-</p></div>
          </div>
        </div>
        <div id="b" class="p-4" v-if="edit_exp">
          <div class="row">
            <div class="col-md-4">
              <label>Total experience<span style="color: red">*</span></label>
            </div>
            <div class="col-md-4">
              <v-select id="total_experience" name="totalExp" v-model="toEdit.exp" :items="exp.options"  item-value="value" item-text="text" @input="validate.checkIfEmpty(toEdit.exp, 'exp', 'Select total experience')" clearable outlined deletable-chips></v-select>
              <div class="error-feedback" v-if="(typeof validate.response.exp != 'undefined' && !validate.response.exp.val)">
                {{ validate.response.exp.msg }}
              </div>
            </div>
            <div class="error-feedback" v-if="exp_error">{{exp_err_msg}}</div>
            <div class="col-md-4 form-group">
              <button class="btn btn-primary float-right" @click.prevent="toEditExp()">Save</button>
            </div>
          </div>
        </div>
      </form>
      <hr>
      <h5 class="text-primary p-3">Experience<span class="float-right" style="font-size: 14px;"><button id="btnchng" class="btn btn-primary" @click.prevent="add_company = !add_company">Add</button></span></h5><hr>
      <form>
        <div class="p-4" id="d" v-if="add_company">
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Company Name<span style="color: red">*</span></label></div>
            <div class="col-lg-8">
              <v-text-field v-model="toEdit.company.worked_company_name" type="text" placeholder="Company Name" @input="validate.checkIfEmpty(toEdit.company.worked_company_name, 'company_name', 'Enter company name')" outlined></v-text-field>
              <div class="error-feedback" v-if="(typeof validate.response.company_name != 'undefined' && !validate.response.company_name.val)">
                {{ validate.response.company_name.msg }}
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Designation<span style="color: red">*</span></label></div>
            <div class="col-lg-8">
              <v-autocomplete id="designation" name="designation" v-model="toEdit.company.designation" :items="jobrole"  item-value="text" item-text="text" @input="validate.checkIfEmpty(toEdit.company.designation, 'designation', 'Select designation')"  clearable outlined deletable-chips></v-autocomplete>
              <div class="error-feedback" v-if="(typeof validate.response.designation != 'undefined' && !validate.response.designation.val)">
                {{ validate.response.designation.msg }}
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Working since(From)<span style="color: red">*</span></label></div>
            <div class="col-lg-4">
              <input type="date" name="Present" v-model="toEdit.company.work_duration_from" class="form-control fields mb-1" value="to Present" @input="validate.checkIfEmpty(toEdit.company.work_duration_from, 'duration_from', 'Enter working from')">
              <div class="error-feedback" v-if="(typeof validate.response.duration_from != 'undefined' && !validate.response.duration_from.val)">
                {{ validate.response.duration_from.msg }}
              </div>
            </div>
            <div class="col-lg-4">
              <input type="text" name="Present" class="form-control fields" value="to Present" disabled="disabled" v-if="toEdit.company.employer_status == 1">
              <input type="date" name="duration_to" v-model="toEdit.company.work_duration_to" class="form-control fields"  v-if="toEdit.company.employer_status == 0" >
              <div class="error-feedback" v-if="(typeof validate.response.duration_to != 'undefined' && !validate.response.duration_to.val)">
                {{ validate.response.duration_to.msg }}
              </div>
            </div>
          </div>
          <div class="row" v-if="!this.current_company_status">
            <div class="col-lg-4"></div>
            <div class="custom-control custom-checkbox col-lg-4 pt-0 pl-10">
              <input type="checkbox" class="custom-control-input" id="hidesalary" name="hidesalary" @click="checkWorkingStatus">
              <label class="custom-control-label text-secondary" for="hidesalary" style="font-size:14px;">Currently working</label>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Skills<span style="color: red">*</span></label></div>
            <div class="col-lg-8">
              <v-autocomplete id="skills" name="skills" v-model="toEdit.company.skills" :items="skills"  item-value="text" item-text="text" @input="validate.checkIfEmpty(toEdit.company.skills, 'skills', 'Select skills')"  clearable outlined deletable-chips chips multiple></v-autocomplete>
              <div class="error-feedback" v-if="(typeof validate.response.skills != 'undefined' && !validate.response.skills.val)">
                {{ validate.response.skills.msg }}
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Current Salary Per Month</label></div>
            <div class="col-lg-8"><v-text-field type="number" name="salary" v-model="toEdit.company.current_salary" clearable outlined></v-text-field></div>
          </div>
          <div class="error-feedback" v-if="company_error">{{company_err_msg}}</div>
          <div class="row mb-1">
            <div class="col-lg-12">
              <button class="btn btn-primary float-right" @click.prevent="addCompany()">Save</button>
            </div>
          </div>
          <hr>
        </div>
        <div v-if="current_company || prev_companies.length">
          <div class="p-4" id="c" v-if="current_company">
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Company Name<span style="color: red">*</span></label></div>
              <div class="col-lg-8" v-if="current_company.worked_company_name"><p>{{current_company.worked_company_name}}</p></div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Designation<span style="color: red">*</span></label></div>
              <div class="col-lg-8" v-if="current_company.designation"><p>{{current_company.designation}}</p></div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Working since(From)<span style="color: red">*</span></label></div>
              <div class="col-lg-8" v-if="current_company.work_duration_month_from && current_company.work_duration_year_from"><p>{{getMonth(current_company.work_duration_month_from)}} - {{current_company.work_duration_year_from}} to Present</p></div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Skills<span style="color: red">*</span></label></div>
              <div class="col-lg-8" v-if="current_company.skills"><p>{{current_company.skills}}</p></div>
            </div>
            <div class="row">
              <div class="col-lg-4"><label class="text-secondary">Current Salary Per Month</label></div>
              <div class="col-lg-8" v-if="current_company.current_salary"><p>{{current_company.current_salary}}</p></div>
              <div class="col-lg-8" v-if="!current_company.current_salary"><p>-</p></div>
            </div>
            <div class="row">
                <!-- <div class="col-lg-2"><button id="edit-company" class="btn btn-primary">EDIT</button></div> -->
                <div class="col-lg-1"><button id="remove-company" class="btn btn-primary" @click.prevent="removeCompany(current_company.id)">Remove</button></div>
              </div>
            <hr>
          </div>
          <div class="previous_companies" v-if="prev_companies.length">
            <div class="p-4" id="c" v-for="(company, index) in prev_companies" :key="index">
              <div class="row">
                <div class="col-lg-4"><label class="text-secondary">Company Name<span style="color: red">*</span></label></div>
                <div class="col-lg-8" v-if="company.worked_company_name"><p>{{company.worked_company_name}}</p></div>
              </div>
              <div class="row">
                <div class="col-lg-4"><label class="text-secondary">Designation<span style="color: red">*</span></label></div>
                <div class="col-lg-8" v-if="company.designation"><p>{{company.designation}}</p></div>
              </div>
              <div class="row">
                <div class="col-lg-4"><label class="text-secondary">Working since(From)<span style="color: red">*</span></label></div>
                <div class="col-lg-8" v-if="company.work_duration_month_from && company.work_duration_year_from"><p>{{getMonth(company.work_duration_month_from)}} - {{company.work_duration_year_from}} to {{getMonth(company.work_duration_month_to)}} - {{company.work_duration_year_to}}</p></div>
              </div>
              <div class="row">
                <div class="col-lg-4"><label class="text-secondary">Skills<span style="color: red">*</span></label></div>
                <div class="col-lg-8" v-if="company.skills"><p>{{company.skills}}</p></div>
              </div>
              <div class="row">
                <div class="col-lg-4"><label class="text-secondary">Current Salary Per Month</label></div>
                <div class="col-lg-8" v-if="company.current_salary"><p>{{company.current_salary}}</p></div>
              </div>
              <div class="row">
                <!-- <div class="col-lg-2"><button id="edit-company" class="btn btn-primary">EDIT</button></div> -->
                <div class="col-lg-1"><button id="remove-company" class="btn btn-primary" @click.prevent="removeCompany(company.id)">Remove</button></div>
              </div>
              <hr v-if="prev_companies.length != index+1">
            </div>
          </div>
        </div>
        <div class="text-center" v-else>
          <h6 class="mb-5" style="color:#727272"><strong>No experience added</strong></h6>
        </div>
      </form>
      <hr>
      <!-- <h5 class="text-primary p-3">Previous Company<span class="float-right" style="font-size: 14px;"><button id="ef" class="btn btn-primary">Edit</button></span></h5> <hr>
      <form>              
        <div class="p-4" id="e">
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Company Name<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><p>Horizon</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Designation<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><p>Associate Software Engineer</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Working since(From)<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><p>Jan - 2020 to May - 2020</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Skills<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><p>Html,CSS,Java</p></div>
          </div>
        </div>
        <div class="p-4" id="f" style="display: none;">
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Company Name<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><input class="form-control fields" type="text" name="" required=""></div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Designation<span style="color: red">*</span></label></div>
            <div class="col-lg-8">
              <input list="jobrole" class="form-control fields" type="text" name="" required="">
              <datalist id="jobrole">
                <option value="Software Engineer"></option>
              </datalist>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Working since(From)<span style="color: red">*</span></label></div>
            <div class="col-lg-4"><input type="month" name="Present1" class="form-control fields mb-1" value="to Present" required=""></div>
            <div class="col-lg-4"><input type="month" name="Present2" class="form-control fields mb-1" value="to Present" required=""></div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Skills<span style="color: red">*</span></label></div>
            <div class="col-lg-8">
              <input class="form-control fields" list="skills" type="text" name="skills" required="">
              <datalist id="skills">
                <option value="HTML"></option>
              </datalist>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"></div>
            <div class="col-lg-3"><p class="text-primary mt-3" style="cursor: pointer;">Add more employer</p></div>
            <div class="col-lg-3"><p class="text-primary mt-3" style="cursor: pointer;">Remove</p></div>
            <div class="col-lg-2">
              <button class="btn btn-primary float-right">Save</button>
            </div>
          </div>
        </div>
      </form>
      <hr>  -->
      <h5 class="text-primary p-3">Career Preference<span class="float-right" style="font-size: 14px;"><span id="gh" role="button" @click.prevent ="edit_career_pref = !edit_career_pref"><img :src="$S3_url+'/Assets/images/edit-icon-black.svg'" alt="edit experience" title="edit experience" width="25"></span></span></h5>           
      <hr>
      <form>
        <div class="p-4" id="g" v-if="!edit_career_pref">
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Job role<span style="color: red">*</span></label></div>
            <div class="col-lg-8" v-if="user_role.length"><p>{{user_role.join()}}</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Preferred Location<span style="color: red">*</span></label></div>
            <div class="col-lg-8"><p>{{user_details.user_city.city_name}}</p></div>
          </div>
          <div class="row">
            <div class="col-lg-4"><label class="text-secondary">Expected Salary(Annual)</label></div>
            <div class="col-lg-8">
              <p v-if="this.user_career.preferred_salary">{{this.user_career.preferred_salary}}</p>
              <p v-if="!this.user_career.preferred_salary">-</p>
              </div>
          </div>
        </div>
        <div class="p-4" id="h" v-if="edit_career_pref">
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Job role<span style="color: red">*</span></label></div>
            <div class="col-lg-8">
              <v-autocomplete v-model="toEdit.career_preference.jobrole" :items="jobrole" item-value="value" item-text="text" @input="validate.jobroleValidation(toEdit.career_preference.jobrole, 'jobrole')" clearable chips outlined multiple deletable-chips hide-selected></v-autocomplete>
              <div class="error-feedback" v-if="(typeof validate.response.jobrole != 'undefined' && !validate.response.jobrole.val)">
                {{ validate.response.jobrole.msg }}
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Preferred Location<span style="color: red">*</span></label></div>
            <div class="col-lg-8">
              <v-autocomplete v-model="toEdit.career_preference.preferred_location" :items="cities" item-text="text" item-value="id" @input="validate.checkIfEmpty(toEdit.career_preference.preferred_location,'cities','Select preferred location')" clearable outlined> 
                <template #label>Current city<span class="red--text"><strong>* </strong></span></template>
                <template slot="item" slot-scope="data">
                  <template v-if = typeof(data.item) != 'object'>
                    <v-list-item-content v-text="data.item.text"/>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.text"/>
                      <v-list-item-sub-title v-html="data.item.group"/>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <div class="error-feedback" v-if="(typeof validate.response.cities != 'undefined' && !validate.response.cities.val)">
                {{ validate.response.cities.msg }}
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-lg-4"><label class="text-secondary">Expected Salary(Annual)</label></div>
            <div class="col-lg-8"><input type="number" v-model="toEdit.career_preference.salary" class="form-control fields" name="expsal" min="0"></div>
          </div>
          <div class="error-feedback" v-if="career_error">{{career_err_msg}}</div>
          <div class="row mb-1">
            <div class="col-lg-12">
              <button class="btn btn-primary float-right" @click.prevent="updateCareer()">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header hide-backdrop centered>
      <div class="container text-center">Updated</div>
    </b-modal>
  </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
import validation from '../libraries/validFormat' ;
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../assets/hire.css';
export default {
    name:'ExperienceDetails',
    data(){
      return {
        validate: new validation(),
        action: new Actions(),
        user_details:this.$attrs.user.personal_details,
        user_career : this.$attrs.user.careerPreferencesDetail,
        current_company : this.$attrs.user.current_company,
        current_company_status : this.$attrs.user.current_company?1:0,
        prev_companies : this.$attrs.user.previous_companies,
        user_exp: null,
        edit_exp: false,
        add_company:false,
        edit_prev_company: false,
        edit_career_pref:false,
        designations:[],
        skills:[],
        user_role:[],
        cities:this.$store.state.states.locations.locations,
        jobrole:this.$store.state.jobrole.jobrole.jobrole,
        can_edit:0,
        toEdit:{
          exp:null,
          company:{
            worked_company_name:null,
            designation: null,
            work_duration_from:null,
            work_duration_to:null,
            skills:null,
            current_salary:0,
            employer_status:0,
            role:1
          },
          career_preference:{
            jobrole:[],
            preferred_location:null,
            salary:null ,
            job_type:2
          }
        },
        exp: {
          options: [
            { value: 1, text: "0 Month(Fresher)" },
            { value: 6, text: "06 Months" },
            { value: 12, text: "1 Year" },
            { value: 24, text: "2 year" },
            { value: 36, text: "3 year" },
            { value: 48, text: "4 year" },
            { value: 60, text: "5 year" },
            { value: 72, text: "6 year" },
            { value: 84, text: "6+ year" },
          ],
        },
        exp_error : false,
        exp_err_msg:null,
        company_error:false,
        company_err_msg:null,
        career_error:false,
        career_err_msg:null,
      }
    },
    beforeMount(){
      if(this.user_details.total_work_experience == 0){
        this.user_details.total_work_experience = 1;
      }
      this.getExp(this.user_details.total_work_experience);
      this.getDesignations();
      this.getSkills();
      this.setJobRole();
      this.setCareerDetails();
      this.setExperienceDetails();
    },
    methods: {
      getExp(id){
        this.toEdit.exp = id;
        this.exp.options.forEach(e => {
          if(e.value == id){
            this.user_exp = e.text;
          }
        })
      },
      getMonth(data){
        var mm = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
        return mm[data+1]
      },
      setCareerDetails(){
        if(this.user_career.prefered_city == null){
          this.toEdit.career_preference.preferred_location = this.user_details?.user_city.city_id;
        }else{
          this.toEdit.career_preference.preferred_location = this.user_career.prefered_city; 
        }
        this.toEdit.career_preference.salary = (this.user_career.preferred_salary)?this.user_career.preferred_salary:null;
      },
      setExperienceDetails(){},
      getDesignations(){
        this.action.getDesignations().then(res => {
          res.designations.forEach(desig => {
            this.designations.push({value:desig.designation_id, text:desig.designation_name})
          })
        })
      },
      getSkills(){
        this.action.getSkills().then(res => {
          res.skills.forEach(skill => {
            this.skills.push({value:skill.id, text:skill.skill});
          })
        })
      },
      setJobRole: function(){
        this.user_role = [];
        this.toEdit.career_preference.jobrole =[];
        this.$store.state.jobrole.jobrole.jobrole.forEach((element) => {
            if(element.value == this.user_career.fst_prefered_role){
              this.user_role.push(element.text)
            }
            if(element.value == this.user_career.snd_prefered_role){
              this.user_role.push(element.text)
            }
            if(element.value == this.user_career.trd_prefered_role){
              this.user_role.push(element.text)
            }
        })
          if(this.user_career.fst_prefered_role){
            this.toEdit.career_preference.jobrole.push(this.user_career.fst_prefered_role);
          }
          if(this.user_career.snd_prefered_role){
            this.toEdit.career_preference.jobrole.push(this.user_career.snd_prefered_role);
          }
          if(this.user_career.trd_prefered_role){
            this.toEdit.career_preference.jobrole.push(this.user_career.trd_prefered_role);
          }
      },
      checkWorkingStatus(e){
        this.toEdit.company.employer_status = (e.target.checked)?1:0;
      },
      toEditExp(){
        this.can_edit = 0;
        this.exp_error = false;
        this.exp_err_msg = null;

        if(this.validate.checkIfEmpty(this.toEdit.exp, "exp", 'Select total experience')?.val === false){
          this.can_edit++;
        }
        if(this.can_edit == 0){
          this.action.editExperience(this.toEdit.exp,this.$cookies.get("hire-seeker").token).then(res => {
            if(res.message == 'Work experience Updated...'){
              this.edit_exp = false;
              this.getExp(this.toEdit.exp)
              this.$refs["success"].show();
              setTimeout(() => {
                this.$refs["success"].hide();
              },2000)
            }else{
              this.exp_error = true;
              this.exp_err_msg = "Network error! Please check your internet connection.";
            }
          }).catch(err =>{
            console.log(err);
            this.exp_error = true;
            if(window.navigator.onLine){
              this.exp_err_msg = "System error! Please try again.";
            }else{
              this.exp_err_msg = "Network error! Please check your internet connection.";
            }
          })
        }else{
          this.exp_error = true;
          this.exp_err_msg = "Fill all mandatory fields.";
        }
      },
      addCompany(){
        this.can_edit = 0;
        this.company_error = false;
        this.company_err_msg = null;
        
        if(this.validate.checkIfEmpty(this.toEdit.company.worked_company_name, "company_name", 'Enter company name')?.val === false){
          this.can_edit++;
        }
        if(this.validate.checkIfEmpty(this.toEdit.company.designation, "designation", 'Select designation')?.val === false){
          this.can_edit++;
        }
        if(this.validate.checkIfEmpty(this.toEdit.company.work_duration_from, "duration_from", 'Enter working from')?.val === false){
          this.can_edit++;
        }
        if(this.toEdit.company.employer_status == 0){
          if(this.validate.checkIfEmpty(this.toEdit.company.work_duration_to, "duration_to", 'Enter working to')?.val === false){
            this.can_edit++;
          }
        }
        if(this.validate.checkIfEmpty(this.toEdit.company.skills, "skills", 'Select skills')?.val === false){
          this.can_edit++;
        }
        if(this.can_edit == 0){
          this.toEdit.company.skills = (this.toEdit.company.skills)?this.toEdit.company.skills.join():null;
          this.toEdit.company.work_duration_from = this.validate.convertDate(this.toEdit.company.work_duration_from.split('-'));
          this.toEdit.company.work_duration_to = (this.toEdit.employer_status == 0)?this.toEdit.company.work_duration_to:(new Date()).toLocaleDateString('ko-KR');
          this.toEdit.company.work_duration_to = this.validate.convertDate(this.toEdit.company.work_duration_to.split('.'));
          this.action.addExperience(this.toEdit.company,this.$cookies.get("hire-seeker").token).then(res => {
            if(res.message == 'Company details saved successfully'){
              this.add_company = false;
              this.$refs["success"].show();
              setTimeout(() => {
                this.$refs["success"].hide();
              },2000);
              if(this.toEdit.company.employer_status == 1){
                this.current_company = {
                  id:res.company.id,
                  worked_company_name: res.company.worked_company_name,
                  designation: res.company.designation,
                  work_duration_year_from: parseInt(res.company.work_duration_year_from),
                  work_duration_year_to: parseInt(res.company.work_duration_year_to),
                  work_duration_month_from: parseInt(res.company.work_duration_month_from),
                  work_duration_month_to: parseInt(res.company.work_duration_month_to),
                  skills: res.company.skills,
                  current_salary: res.company.current_salary 
                }
                this.current_company_status = 1;
              }else{
                this.prev_companies.push({
                  id:res.company.id,
                  worked_company_name: res.company.worked_company_name,
                  designation: res.company.designation,
                  work_duration_year_from: parseInt(res.company.work_duration_year_from),
                  work_duration_year_to: parseInt(res.company.work_duration_year_to),
                  work_duration_month_from: parseInt(res.company.work_duration_month_from),
                  work_duration_month_to: parseInt(res.company.work_duration_month_to),
                  skills: res.company.skills,
                  current_salary: res.company.current_salary
                });
              }
              this.resetFields();
            }else{
              this.company_error = true;
              this.company_err_msg = "Network error! Please check your internet connection.";
            }
          }).catch(err =>{
            console.log(err);
            this.company_error = true;
            if(window.navigator.onLine){
              this.company_err_msg = "System error! Please try again.";
            }else{
              this.company_err_msg = "Network error! Please check your internet connection.";
            }
          })
        }else{
          this.company_error = true;
          this.company_err_msg = "Fill all mandatory fields.";
        }
      },
      updateCareer(){
        this.can_edit = 0;
        this.career_error= false;
        this.career_err_msg = null;

        if (this.validate.jobroleValidation(this.toEdit.career_preference.jobrole, "jobrole")?.val === false) {
          this.can_edit++;
        }
        if(this.validate.checkIfEmpty(this.toEdit.career_preference.preferred_location, "cities", 'Select preferred location')?.val === false){
          this.can_edit++;
        }
        if(this.can_edit == 0){
          this.action.updateCareerPreference(this.toEdit.career_preference,this.$cookies.get("hire-seeker").token).then(res => {
            if(res.message =='Career Preferences Detail Updated...'){
              this.edit_career_pref = false;
              this.user_role = [];
              this.user_career.fst_prefered_role = (this.toEdit.career_preference.jobrole.length >= 1) ? this.toEdit.career_preference.jobrole[0] : null;
              this.user_career.snd_prefered_role = (this.toEdit.career_preference.jobrole.length >= 2) ? this.toEdit.career_preference.jobrole[1] : null;
              this.user_career.trd_prefered_role = (this.toEdit.career_preference.jobrole.length == 3) ? this.toEdit.career_preference.jobrole[2] : null;
              this.setJobRole();
              this.user_details.user_city.city_id = this.toEdit.career_preference.preferred_location;
              this.getCityNameFromID(this.toEdit.career_preference.preferred_location);
              this.user_career.preferred_salary = (this.toEdit.career_preference.salary)?this.toEdit.career_preference.salary:null;
              this.$refs["success"].show();
              setTimeout(() => {
                this.$refs["success"].hide();
              },2000)
            }else{
              this.career_error = true;
              this.career_err_msg = "Network error! Please check your internet connection.";
            }
          }).catch(err =>{
            console.log(err);
            this.career_error = true;
            if(window.navigator.onLine){
              this.career_err_msg = "System error! Please try again.";
            }else{
              this.career_err_msg = "Network error! Please check your internet connection.";
            }
          })
        }else{
          this.career_error = true;
          this.career_err_msg = "Fill all mandatory fields.";
        }
      },
      getCityNameFromID: async function(id){
        await this.action.getStates().then(async (data) => {
          await data.states.forEach(async (element) => {
            element.cities.forEach(city => {
              if(city.city_id == id){
                this.user_details.user_city.city_name = city.city_name;
              }
            });
          });
        });
      },
      resetFields: function(){
        this.toEdit.exp = null;
        this.toEdit.company.worked_company_name = null;
        this.toEdit.company.designation = null;
        this.toEdit.company.work_duration_from = null;
        this.toEdit.company.work_duration_to = null;
        this.toEdit.company.skills = null;
        this.toEdit.company.current_salary = 0;
        this.toEdit.company.employees = 0;
        this.toEdit.company.role = [];
        this.toEdit.career_preference.jobrole = [];
        this.toEdit.career_preference.preferred_location = null;
        this.toEdit.career_preference.expected_salary = null;
        this.toEdit.career_preference.job_type = 2;
        this.toEdit.company.employer_status = 0;

        if(this.user_details.total_work_experience == 0){
          this.user_details.total_work_experience = 1;
        }
        this.getExp(this.user_details.total_work_experience);
        this.setJobRole();
        this.setCareerDetails();
        this.setExperienceDetails();
      },
      removeCompany(id){
        this.action.removeCompany(id,this.$cookies.get("hire-seeker").token).then(res => {
          if(res.message == 'Company deleted successfully'){
            console.log('deleted');
            this.prev_companies = this.prev_companies.filter(company => company.id !== id);
          }
        });
      }
    }
}
</script>

<style>
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
  min-height:55px !important;
}
.v-text-field__details{
  display: none !important;
}
.v-input__slot{
  margin-bottom:0 !important;
}
</style>
<style scoped>
input{padding: 28px 0px 28px 15px !important;}.error-feedback{position: absolute;}
</style>